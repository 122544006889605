<template>
  <form>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="close"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <!--                    <v-col cols="12" sm="6">-->
            <!--                      <v-select-->
            <!--                        :label="$t('bank_id')"-->
            <!--                        v-model="editedItem.bank_id"-->
            <!--                        name="bank_id"-->
            <!--                        :items="bankList"-->
            <!--                        item-text="bank_name"-->
            <!--                        item-value="bank_id"-->
            <!--                      ></v-select>-->
            <!--                    </v-col>-->
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('bank_card_name')"
                v-model="editedItem.bank_card_name"
                name="bank_card_name"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('bank_card_number')"
                v-model="editedItem.bank_card_number"
                name="bank_card_number"
                type="text"
              />
            </v-col>
            <v-col cols="12" :sm="$app_country === 'CN' ? 6 : 12">
              <v-combobox
                :label="$t('bank_card_bank_code')"
                name="bank_card_bank_code"
                :items="Banks"
                item-text="name"
                item-value="name"
                :return-object="false"
                v-model="editedItem.bank_card_bank_code"
              >
              </v-combobox>
            </v-col>
            <template v-if="$app_country === 'CN'">
              <v-col cols="12" sm="6">
                <v-text-field
                  :label="$t('bank_card_branch')"
                  v-model="editedItem.bank_card_branch"
                  name="bank_card_branch"
                  type="text"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-combobox
                  :label="$t('bank_card_province')"
                  name="bank_card_province"
                  :items="province"
                  item-text="name"
                  item-value="name"
                  :return-object="false"
                  v-model="editedItem.bank_card_province"
                >
                </v-combobox>
                <!--                      <v-text-field-->
                <!--                        :label="$t('bank_card_province')"-->
                <!--                        v-model="editedItem.bank_card_province"-->
                <!--                        name="bank_card_province"-->
                <!--                        type="text"-->
                <!--                      />-->
              </v-col>
              <v-col cols="12" sm="6">
                <v-combobox
                  :label="$t('bank_card_city')"
                  name="bank_card_city"
                  :items="city"
                  item-text="name"
                  item-value="name"
                  :return-object="false"
                  v-model="editedItem.bank_card_city"
                >
                </v-combobox>
                <!--                      <v-text-field-->
                <!--                        :label="$t('bank_card_city')"-->
                <!--                        v-model="editedItem.bank_card_city"-->
                <!--                        name="bank_card_city"-->
                <!--                        type="text"-->
                <!--                      />-->
              </v-col>
            </template>
            <!--                    last_tx_time: '',-->
            <v-col cols="12" sm="6">
              <v-checkbox
                v-model="editedItem.bank_card_enabled"
                :label="$t('bank_card_enabled')"
                name="bank_card_enabled"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-combobox
                :label="$t('currency')"
                :items="CurrencyList"
                item-text="currency_code"
                item-value="currency_code"
                :return-object="false"
                v-model="editedItem.currency_code"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('max_daily_tx_amt')"
                v-model.number="editedItem.max_daily_tx_amt"
                name="max_daily_tx_amt"
                type="number"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('max_tx_limit')"
                v-model.number="editedItem.max_tx_limit"
                name="max_tx_limit"
                type="number"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('max_hourly_tx_limit')"
                v-model.number="editedItem.max_hourly_tx_limit"
                name="max_hourly_tx_limit"
                type="number"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('min_tx_amt')"
                v-model.number="editedItem.min_tx_amt"
                name="min_tx_amt"
                type="number"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('max_tx_amt')"
                v-model.number="editedItem.max_tx_amt"
                name="max_tx_amt"
                type="number"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('bank_card_url')"
                v-model="editedItem.bank_card_url"
                type="url"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :label="$t('supported_paytype')"
                :items="supported_paytype"
                v-model="editedItem.paytype"
                multiple
                chips
              />
            </v-col>
            <v-col cols="12">
              <v-combobox
                :label="$t('p2p_bank_code')"
                name="p2p_bank_code"
                :items="bankList"
                item-text="bank_name"
                item-value="bank_code"
                :return-object="false"
                v-model="editedItem.p2p_bank_code"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-file-input
                :label="$t('qr_code')"
                outlined
                counter
                show-size
                v-model="editedItem.newQr"
                accept="image/*"
              ></v-file-input>
            </v-col>
            <v-col cols="12" sm="6">
              <v-dialog
                fullscreen hide-overlay
                v-model="qrDialog"
                v-if="editedItem.qr"
              >
                <template
                  v-slot:activator="{ on, attrs }"
                >
                  <v-hover v-slot:default="{ hover }">
                    <v-img
                      :src="apiEndpoint + editedItem.qr"
                      class="primary"
                      height="125"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-1 white--text text-center"
                        style="height: 100%;"
                      >
                        {{ $t('click_expand_receipt') }}
                      </div>
                    </v-img>
                  </v-hover>
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ $t('qr_code') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn icon dark @click="qrDialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-img
                    :src="apiEndpoint + editedItem.qr"
                  ></v-img>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('sms_callback_phone_no')"
                v-model="editedItem.sms_callback_phone_no"
                type="text"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('sms_callback_match')"
                v-model="editedItem.sms_callback_match"
                type="text"
              />
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="editedItem.sms_callback_from"
                :label="$t('sms_callback_from')"
                multiple
                chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('press_key') }} <kbd>enter</kbd> {{ $t('to_create') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">{{$t('cancel')}}</v-btn>
        <v-btn color="blue darken-1" text @click="save" :loading="busy">{{$t('submit')}}</v-btn>
      </v-card-actions>
    </v-card>
  </form>
</template>

<script>
  import axios from 'axios';
  import chinaMixins from "../../mixins/chinaMixins";
  import y3Mixins from "../../mixins/y3Mixins";
  import FormData from "form-data";
  import globalMixins from "../../mixins/globalMixins";
  import currencyMixins from "../../mixins/currencyMixins";
  import { PayType } from "../../constants/y3.js";

  export default {
    name: 'BankCardForm',
    props: ['formTitle', 'editedItem', 'editedIndex', 'dialogVisible'],
    mixins: [chinaMixins, y3Mixins, globalMixins, currencyMixins],

    watch: {
      dialogVisible: {
        immediate: true,
        handler(visible) {
          if (visible) {
            this.fetchBank();
            this.fetchCurrency();
          }
        }
      }
    },
    data: function() {
      return {
        busy: false,
        commissionList: [],
        qrDialog: false,
        supported_paytype: PayType.map(x => x.code),
        bankList: [],
      }
    },
    methods: {
      async fetchBank () {
        this.busy = true;
        const _this = this;
        await axios.get('/app/api/bank')
          .then(function (response) {
            _this.bankList = response.data.data;
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      fetch() {
        this.$emit('fetch');
      },
      close () {
        this.$emit('close');
      },
      save () {
        if (this.editedIndex > -1) {
          this.updateBank();
        } else {
          this.addBank();
        }
      },
      updateBank () {
        this.busy = true;
        const _this = this;

        const formData = new FormData();
        // formData.append('bank_id', this.editedItem.bank_id);
        // formData.append('bank_card_id', this.editedItem.bank_card_id);
        formData.append('bank_card_name', this.editedItem.bank_card_name);
        formData.append('bank_card_number', this.editedItem.bank_card_number);
        formData.append('bank_card_enabled', this.editedItem.bank_card_enabled);
        formData.append('currency_code', this.editedItem.currency_code);
        formData.append('max_daily_tx_amt', this.editedItem.max_daily_tx_amt);
        formData.append('max_tx_limit', this.editedItem.max_tx_limit);
        formData.append('max_hourly_tx_limit', this.editedItem.max_hourly_tx_limit);
        formData.append('min_tx_amt', this.editedItem.min_tx_amt);
        formData.append('max_tx_amt', this.editedItem.max_tx_amt);
        formData.append('bank_card_bank_code', this.editedItem.bank_card_bank_code);
        formData.append('bank_card_branch', this.editedItem.bank_card_branch);
        formData.append('bank_card_city', this.editedItem.bank_card_city);
        formData.append('bank_card_province', this.editedItem.bank_card_province);
        formData.append('p2p_bank_code', this.editedItem.p2p_bank_code);

        if (this.editedItem.bank_card_url) {
          formData.append('bank_card_url', this.editedItem.bank_card_url);
        }

        for (const paytype of this.editedItem.paytype) {
          formData.append('paytype[]', paytype);
        }

        if (this.editedItem.newQr) {
          formData.append('qr', this.editedItem.newQr);
        }

        if (this.editedItem.sms_callback_phone_no) {
          formData.append(
            'sms_callback_phone_no', this.editedItem.sms_callback_phone_no
          );
        }

        for (const sms_callback_from of this.editedItem.sms_callback_from) {
          formData.append('sms_callback_from[]', sms_callback_from);
        }

        if (this.editedItem.sms_callback_match) {
          formData.append(
            'sms_callback_match', this.editedItem.sms_callback_match
          );
        }

        axios({
          url: `/app/api/bankcard/${this.editedItem.bank_card_id}`,
          method: 'PUT',
          data: formData,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        }).then(function () {
            _this.fetch();
            _this.close()
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      addBank () {
        this.busy = true;
        const _this = this;

        const formData = new FormData();
        // formData.append('bank_id', this.editedItem.bank_id);
        formData.append('bank_card_name', this.editedItem.bank_card_name);
        formData.append('bank_card_number', this.editedItem.bank_card_number);
        formData.append('bank_card_enabled', this.editedItem.bank_card_enabled);
        formData.append('currency_code', this.editedItem.currency_code);
        formData.append('max_daily_tx_amt', this.editedItem.max_daily_tx_amt);
        formData.append('max_tx_limit', this.editedItem.max_tx_limit);
        formData.append('max_hourly_tx_limit', this.editedItem.max_hourly_tx_limit);
        formData.append('min_tx_amt', this.editedItem.min_tx_amt);
        formData.append('max_tx_amt', this.editedItem.max_tx_amt);
        formData.append('bank_card_bank_code', this.editedItem.bank_card_bank_code);
        formData.append('bank_card_branch', this.editedItem.bank_card_branch);
        formData.append('bank_card_city', this.editedItem.bank_card_city);
        formData.append('bank_card_province', this.editedItem.bank_card_province);
        formData.append('p2p_bank_code', this.editedItem.p2p_bank_code);

        if (this.editedItem.bank_card_url) {
          formData.append('bank_card_url', this.editedItem.bank_card_url);
        }

        for (const paytype of this.editedItem.paytype) {
          formData.append('paytype[]', paytype);
        }

        if (this.editedItem.newQr) {
          formData.append('qr', this.editedItem.newQr);
        }

        if (this.editedItem.sms_callback_phone_no) {
          formData.append(
            'sms_callback_phone_no', this.editedItem.sms_callback_phone_no
          );
        }

        for (const sms_callback_from of this.editedItem.sms_callback_from) {
          formData.append('sms_callback_from[]', sms_callback_from);
        }

        if (this.editedItem.sms_callback_match) {
          formData.append(
            'sms_callback_match', this.editedItem.sms_callback_match
          );
        }

        axios({
          url: '/app/api/bankcard',
          method: 'POST',
          data: formData,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        }).then(function () {
            _this.fetch();
            _this.close();
          })
          .catch(function (error) {
            // Show error.
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
    }
  }
</script>
