import { province, city } from 'province-city-china/data';

let chinaMixins = {
  data: () => ({
    province: province,
    city: city,
    selectedProvince: null,
  }),
  computed: {
    filteredCity () {
      if (!this.selectedProvince) {
        return this.city;
      }
      return city.filter(function(oneCity) {
        return oneCity.province === this.selectedProvince;
      });
    }
  },
  methods: {

  }
}

export default chinaMixins;
