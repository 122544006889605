var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('bank_card_list')))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.bankCardsLoading,"headers":_vm.headers,"items":_vm.bankCardList,"item-key":"bank_card_id","show-select":""},scopedSlots:_vm._u([{key:"item.max_daily_tx_amt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.max_daily_tx_amt ? item.max_daily_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : '')+" ")]}},{key:"item.max_tx_limit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.max_tx_limit ? item.max_tx_limit.toLocaleString() : '')+" ")]}},{key:"item.max_hourly_tx_limit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.max_hourly_tx_limit ? item.max_hourly_tx_limit.toLocaleString() : '')+" ")]}},{key:"item.min_tx_amt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.min_tx_amt ? item.min_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : '')+" ")]}},{key:"item.max_tx_amt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.max_tx_amt ? item.max_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : '')+" ")]}},{key:"item.assign_client",fn:function(ref){
var item = ref.item;
return [(item.assign_client)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":item.assign_client ? 'javascript:void(0);' : false}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.assign_client.length)+" ")])]}}],null,true)},[(item.assign_client.length === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('no_assigned_clients'))+" ")]):_c('div',_vm._l((item.assign_client),function(id){return _c('div',{key:id},[_vm._v(" "+_vm._s(id)+" ")])}),0)]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetchBankCards}},[_vm._v(_vm._s(_vm.$t('refresh')))])]},proxy:true}]),model:{value:(_vm.formattedBankCards),callback:function ($$v) {_vm.formattedBankCards=$$v},expression:"formattedBankCards"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.busy},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('save')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }