var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('all'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userList,"search":_vm.search,"loading":_vm.busyInit},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('client')))]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.addClient}},[_vm._v(_vm._s(_vm.$t('new_client')))]),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return undefined}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('client-form',{attrs:{"form-title":_vm.formTitle,"edited-item":_vm.editedItem,"edited-index":_vm.editedIndex},on:{"fetch":_vm.fetch,"close":_vm.close}})],1),_c('v-dialog',{model:{value:(_vm.bankCardDialog),callback:function ($$v) {_vm.bankCardDialog=$$v},expression:"bankCardDialog"}},[_c('client-bank-card',{attrs:{"bank-card-list":_vm.bankCardList,"edited-item":_vm.editedItem,"bank-cards-loading":_vm.bankCardsLoading},on:{"fetch":_vm.fetch,"close":_vm.close,"fetchBankCards":_vm.fetchBankCards}})],1),_c('v-dialog',{attrs:{"max-width":"1200px"},model:{value:(_vm.trxCfgDialog),callback:function ($$v) {_vm.trxCfgDialog=$$v},expression:"trxCfgDialog"}},[_c('client-trx-cfg-form',{attrs:{"default-item":_vm.defaultItem,"edited-item":_vm.editedItem,"dialog-visible":_vm.trxCfgDialog},on:{"fetch":_vm.fetch,"close":_vm.close}})],1)],1)]},proxy:true},{key:"item.client_id",fn:function(ref){
var item = ref.item;
return [(item.client_id)?_c('router-link',{attrs:{"to":{name: 'contact.client', query: {q: item.client_id.toString()}}}},[_vm._v(_vm._s(item.client_id))]):_vm._e()]}},{key:"item.user_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getKeyByValue(_vm.UserType, item.user_type))+" ")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [(item.balance)?[_vm._l((item.balance.filter(function (x) { return x.amount > 0; })),function(balance,key){return _c('div',{key:key,style:({ whiteSpace: 'nowrap' })},[_c('span',{style:({ fontWeight: 'bold' })},[_vm._v(" "+_vm._s(balance.currency)+" ")]),_vm._v("   "+_vm._s(balance.amount.toLocaleString(undefined, {minimumFractionDigits: 2}))+" ")])}),(item.balance.filter(function (x) { return x.amount > 0; }).length === 0)?_c('span',[_vm._v(" 0 ")]):_vm._e()]:_c('span',[_vm._v("-")])]}},{key:"item.user_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getKeyByValue(_vm.UserStatus, item.user_status))+" ")]}},{key:"item.assign_bankcard",fn:function(ref){
var item = ref.item;
return [(item.assign_bankcard)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"javascript:void(0);"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.assign_bankcard.length)+" ")])]}}],null,true)},[(item.assign_bankcard.length === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('no_assigned_bank_cards'))+" ")]):_c('div',_vm._l((item.assign_bankcard),function(id){return _c('div',{key:id},[_vm._v(" "+_vm._s(id)+" ")])}),0)]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item, 'dialog')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","disabled":item.user_type === _vm.UserType.CLIENT_CSA},on:{"click":function($event){return _vm.editItem(item, 'trxCfgDialog')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-abacus ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('client_configure_trx_cfg')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","disabled":item.user_type === _vm.UserType.CLIENT_CSA},on:{"click":function($event){return _vm.editItem(item, 'bankCardDialog')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-credit-card-multiple ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('bank_card_list')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","disabled":item.user_type === _vm.UserType.CLIENT_CSA},on:{"click":function($event){return _vm.addCs(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-multiple-plus ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('add_client_sub_account')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.resetPassword(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-reset ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Reset Password')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","disabled":item.user_type === _vm.UserType.CLIENT_CSA},on:{"click":function($event){return _vm.resetWithdrawalKey(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-key-change ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Reset Withdraw Key')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetch}},[_vm._v(_vm._s(_vm.$t('refresh')))])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }