var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.generateReport.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-combobox',{attrs:{"items":_vm.SORT_KEY,"label":"SORT_KEY","multiple":"","chips":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({key:JSON.stringify(data.item),attrs:{"input-value":data.selected,"disabled":data.disabled},on:{"click:close":function($event){return data.parent.selectItem(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',{staticClass:"accent white--text",attrs:{"left":""},domProps:{"textContent":_vm._s(_vm.selected_SORT_KEY.indexOf(data.item) + 1)}}),_vm._v(" "+_vm._s(data.item)+" ")],1)]}}]),model:{value:(_vm.selected_SORT_KEY),callback:function ($$v) {_vm.selected_SORT_KEY=$$v},expression:"selected_SORT_KEY"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-combobox',{attrs:{"items":_vm.OUTPUT_KEY,"label":"OUTPUT_KEY","multiple":"","chips":"","clearable":""},model:{value:(_vm.selected_OUTPUT_KEY),callback:function ($$v) {_vm.selected_OUTPUT_KEY=$$v},expression:"selected_OUTPUT_KEY"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Filter")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date From","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date To","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"amount_min","clearable":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"amount_max","clearable":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-combobox',{attrs:{"items":_vm.bank_ids,"label":"Bank IDs","multiple":"","chips":"","clearable":""},model:{value:(_vm.selected_bank_ids),callback:function ($$v) {_vm.selected_bank_ids=$$v},expression:"selected_bank_ids"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-combobox',{attrs:{"items":_vm.bank_card_ids,"label":"Bank Card IDs","multiple":"","chips":"","clearable":""},model:{value:(_vm.selected_bank_card_ids),callback:function ($$v) {_vm.selected_bank_card_ids=$$v},expression:"selected_bank_card_ids"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Generate")])],1)],1)],1),(_vm.records)?[_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.records,"single-expand":true,"item-key":"name","show-expand":""},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" More info about "+_vm._s(item.name)+" ")])]}}],null,false,3140005827)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }