var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('new_adjust')))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{staticClass:"pb-0"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.adjust.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"error-messages":errors,"label":_vm.$t('currency'),"items":_vm.CurrencyList,"item-text":"currency_code","item-value":"currency_code","return-object":false},model:{value:(_vm.editedForm.currency_code),callback:function ($$v) {_vm.$set(_vm.editedForm, "currency_code", $$v)},expression:"editedForm.currency_code"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('amount'),"name":"amount","type":"number"},model:{value:(_vm.editedForm.amount),callback:function ($$v) {_vm.$set(_vm.editedForm, "amount", $$v)},expression:"editedForm.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('commission'),"name":"commission","type":"number"},model:{value:(_vm.editedForm.comm),callback:function ($$v) {_vm.$set(_vm.editedForm, "comm", $$v)},expression:"editedForm.comm"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"client_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":_vm.$t('client'),"name":"client_id","items":_vm.userList,"item-text":"display_name","return-object":true,"error-messages":errors},model:{value:(_vm.editedForm.client),callback:function ($$v) {_vm.$set(_vm.editedForm, "client", $$v)},expression:"editedForm.client"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"transaction_number","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('transaction_number'),"name":"transaction_number","type":"text"},model:{value:(_vm.editedForm.transaction_number),callback:function ($$v) {_vm.$set(_vm.editedForm, "transaction_number", _vm._n($$v))},expression:"editedForm.transaction_number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"client_order_id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('client_order_id'),"name":"client_order_id","type":"text"},model:{value:(_vm.editedForm.orderid),callback:function ($$v) {_vm.$set(_vm.editedForm, "orderid", $$v)},expression:"editedForm.orderid"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"remark","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":_vm.$t('remark'),"name":"remark","type":"text","auto-grow":"","rows":"3"},model:{value:(_vm.editedForm.remark),callback:function ($$v) {_vm.$set(_vm.editedForm, "remark", $$v)},expression:"editedForm.remark"}})]}}],null,true)})],1)],1)],1)]}}])})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createAdjust}},[_vm._v(_vm._s(_vm.$t('confirm')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }