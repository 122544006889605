var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('all'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.bankCardList,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('bank_card')))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v(_vm._s(_vm.$t('new_bank_card')))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('bank-card-form',{attrs:{"form-title":_vm.formTitle,"edited-item":_vm.editedItem,"edited-index":_vm.editedIndex,"dialog-visible":_vm.dialog},on:{"fetch":_vm.fetch,"close":_vm.close}})],1)],1)]},proxy:true},{key:"item.max_daily_tx_amt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.max_daily_tx_amt ? item.max_daily_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : '')+" ")]}},{key:"item.max_tx_limit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.max_tx_limit ? item.max_tx_limit.toLocaleString() : '')+" ")]}},{key:"item.max_hourly_tx_limit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.max_hourly_tx_limit ? item.max_hourly_tx_limit.toLocaleString() : '')+" ")]}},{key:"item.min_tx_amt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.min_tx_amt ? item.min_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : '')+" ")]}},{key:"item.max_tx_amt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.max_tx_amt ? item.max_tx_amt.toLocaleString(undefined, {minimumFractionDigits: 2}) : '')+" ")]}},{key:"item.assign_client",fn:function(ref){
var item = ref.item;
return [(item.assign_client)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":item.assign_client ? 'javascript:void(0);' : false}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.assign_client.length)+" ")])]}}],null,true)},[(item.assign_client.length === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('no_assigned_clients'))+" ")]):_c('div',_vm._l((item.assign_client),function(id){return _c('div',{key:id},[_vm._v(" "+_vm._s(id)+" ")])}),0)]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetch}},[_vm._v(_vm._s(_vm.$t('refresh')))])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }