<template>
  <v-container>
    <v-card max-width="700px" class="mx-auto">
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <v-toolbar-title>
          {{$t('settings')}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!--        <v-btn icon :to="{name: 'contact.insert'}">-->
        <!--          <v-icon>mdi-plus-circle</v-icon>-->
        <!--        </v-btn>-->

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs
            align-with-title
            background-color="transparent"
          >
            <v-tabs-slider color="white"></v-tabs-slider>

            <v-tab v-for="(item, index) in items" :to="item.route" :key="index">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <router-view></router-view>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Settings',

    computed: {
      ...mapGetters({
        isRoot: 'auth/isRoot',
        isManager: 'auth/isManager',
        isCsAgent: 'auth/isCsAgent',
        isClient: 'auth/isClient',
        isClientCsa: 'auth/isClientCsa',
      }),
      items() {
        if (this.isClient || this.isRoot) {
          return [
            {
              name: this.$t('password'),
              route: {name: 'settings.password'}
            },
            {
              name: this.$t('withdraw_key'),
              route: {name: 'settings.withdrawalKey'}
            },
          ]
        } else {
          return [
            {
              name: this.$t('password'),
              route: {name: 'settings.password'}
            },
          ]
        }
      }
    }
  }
</script>
