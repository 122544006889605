var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"800px"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('withdrawal')))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('amount'),"name":"amount","type":"number"},model:{value:(_vm.editedItem.amount),callback:function ($$v) {_vm.$set(_vm.editedItem, "amount", $$v)},expression:"editedItem.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"withdraw_key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('withdraw_key'),"name":"withdraw_key","type":"password"},model:{value:(_vm.editedItem.withdraw_key),callback:function ($$v) {_vm.$set(_vm.editedItem, "withdraw_key", $$v)},expression:"editedItem.withdraw_key"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"from_bankflag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"error-messages":errors,"label":_vm.$t('to_bankflag'),"name":"to_bankflag","items":_vm.Banks,"item-text":"name","item-value":"name","return-object":false},model:{value:(_vm.editedItem.to_bankflag),callback:function ($$v) {_vm.$set(_vm.editedItem, "to_bankflag", $$v)},expression:"editedItem.to_bankflag"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"to_cardnumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('to_cardnumber'),"name":"to_cardnumber","type":"text"},model:{value:(_vm.editedItem.to_cardnumber),callback:function ($$v) {_vm.$set(_vm.editedItem, "to_cardnumber", $$v)},expression:"editedItem.to_cardnumber"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"to_cardname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('to_cardname'),"name":"to_cardname","type":"text"},model:{value:(_vm.editedItem.to_cardname),callback:function ($$v) {_vm.$set(_vm.editedItem, "to_cardname", $$v)},expression:"editedItem.to_cardname"}})]}}],null,true)})],1),(_vm.$app_country === 'CN')?[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"to_branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('to_branch'),"name":"to_branch","type":"text"},model:{value:(_vm.editedItem.to_branch),callback:function ($$v) {_vm.$set(_vm.editedItem, "to_branch", $$v)},expression:"editedItem.to_branch"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"to_province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"error-messages":errors,"label":_vm.$t('to_province'),"name":"to_province","items":_vm.province,"item-text":"name","item-value":"name","return-object":false},model:{value:(_vm.editedItem.to_province),callback:function ($$v) {_vm.$set(_vm.editedItem, "to_province", $$v)},expression:"editedItem.to_province"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"to_city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"error-messages":errors,"label":_vm.$t('to_city'),"name":"to_city","items":_vm.city,"item-text":"name","item-value":"name","return-object":false},model:{value:(_vm.editedItem.to_city),callback:function ($$v) {_vm.$set(_vm.editedItem, "to_city", $$v)},expression:"editedItem.to_city"}})]}}],null,true)})],1)]:_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"label":_vm.$t('currency'),"items":_vm.CurrencyList,"item-text":"currency_code","item-value":"currency_code","return-object":false},model:{value:(_vm.editedItem.currency),callback:function ($$v) {_vm.$set(_vm.editedItem, "currency", $$v)},expression:"editedItem.currency"}})],1),_c('validation-provider',{attrs:{"name":"withdrawal_currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"label":_vm.$t('withdrawal_currency'),"error-messages":errors,"row":""},model:{value:(_vm.editedItem.payment_currency),callback:function ($$v) {_vm.$set(_vm.editedItem, "payment_currency", $$v)},expression:"editedItem.payment_currency"}},_vm._l((_vm.paymentCurrencies),function(currency){return _c('v-radio',{key:currency,attrs:{"label":currency,"value":currency}})}),1)]}}],null,true)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"warning","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.$t('client_withdrawal_note'))+" ")]),_c('validation-provider',{attrs:{"name":"tnc","rules":"agree"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"error-messages":errors,"label":_vm.$t('i_agree_tnc')},model:{value:(_vm.agreement),callback:function ($$v) {_vm.agreement=$$v},expression:"agreement"}})]}}],null,true)})],1)],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.busy,"color":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('save')))])],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }