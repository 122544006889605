<template>
    <v-card>
      <v-card-title>
        <span class="headline">{{$t('new_adjust')}}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <ValidationObserver ref="observer" v-slot="{ validate, reset }">
          <form v-on:submit.prevent="adjust">
            <v-row>
              <v-col cols="12">
                <ValidationProvider name="currency" rules="required" v-slot="{ errors }">
                  <v-combobox
                    :error-messages="errors"
                    :label="$t('currency')"
                    :items="CurrencyList"
                    item-text="currency_code"
                    item-value="currency_code"
                    :return-object="false"
                    v-model="editedForm.currency_code"
                  >
                  </v-combobox>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ValidationProvider name="amount" rules="required" v-slot="{ errors }">
                  <v-text-field
                    :error-messages="errors"
                    :label="$t('amount')"
                    name="amount"
                    type="number"
                    v-model="editedForm.amount"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider name="commission" rules="required" v-slot="{ errors }">
                  <v-text-field
                    :error-messages="errors"
                    :label="$t('commission')"
                    name="commission"
                    type="number"
                    v-model="editedForm.comm"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider name="client_id" rules="required" v-slot="{ errors }">
                  <v-combobox
                    :label="$t('client')"
                    name="client_id"
                    :items="userList"
                    item-text="display_name"
                    :return-object="true"
                    v-model="editedForm.client"
                    :error-messages="errors"
                  ></v-combobox>
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider name="transaction_number" rules="" v-slot="{ errors }">
                  <v-text-field
                    :error-messages="errors"
                    :label="$t('transaction_number')"
                    name="transaction_number"
                    type="text"
                    v-model.number="editedForm.transaction_number"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider name="client_order_id" rules="" v-slot="{ errors }">
                  <v-text-field
                    :error-messages="errors"
                    :label="$t('client_order_id')"
                    name="client_order_id"
                    type="text"
                    v-model="editedForm.orderid"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <ValidationProvider name="remark" rules="required" v-slot="{ errors }">
                  <v-textarea
                    :error-messages="errors"
                    :label="$t('remark')"
                    v-model="editedForm.remark"
                    name="remark"
                    type="text"
                    auto-grow
                    rows="3"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="$emit('close')">{{$t('cancel')}}</v-btn>
        <v-btn color="primary" @click="createAdjust">{{$t('confirm')}}</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
  // TODO: Reset form validation when close dialog or after success submit

  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import { required, min_value, max_value } from 'vee-validate/dist/rules';
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import contactMixins from '../../mixins/contactMixins'
  import currencyMixins from '../../mixins/currencyMixins'

  extend('required', required);
  extend('min_value', min_value);
  extend('max_value', max_value);

  export default {
    name: 'CompanyAdjustmentForm',
    props: ['dialog'],
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [contactMixins, currencyMixins],
    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
    },
    mounted () {
      this.fetchUser();
      this.fetchCurrency();
    },
    data: function() {
      return {
        editedForm: {
          clientid: null,
          transaction_number: '', //optional
          orderid: '', //optional
          currency_code: null,
          amount: '0',
          comm: '0',
          remark: '',
        },
        defaultForm: {
          client: null,
          transaction_number: '',
          orderid: '',
          currency_code: null,
          amount: '0',
          comm: '0',
          remark: '',
        },
        userList: [],
        busy: false,
      }
    },
    methods: {
      async createAdjust() {
        const _this = this;

        this.$refs.observer.validate().then(result => {
          if (result) {
            _this.busy = true;

            let postData = {
              currency_code: _this.editedForm.currency_code,
              amount: _this.editedForm.amount,
              comm: _this.editedForm.comm,
              remark: _this.editedForm.remark,
            };
            if (_this.editedForm.transaction_number) {
              postData.transaction_number = _this.editedForm.transaction_number;
            }
            if (_this.editedForm.orderid) {
              postData.orderid = _this.editedForm.orderid;
            }

            axios.post(`/app/api/client/${_this.editedForm.client.user_id}/adjust`, postData
            ).then(function (response) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: response.data.message,
                color: 'success',
              });
              _this.success();
            }).catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            }).finally(function () {
              _this.busy = false;
            })

          }
        });
      },
      async fetchUser () {
        this.busy = true;
        const _this = this;
        await axios.get('/app/api/client')
          .then(function (response) {
            _this.userList = response.data.data.filter((item) => item.user_type === _this.UserType.CLIENT);
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      success () {
        this.$emit('success');
        this.editedForm = Object.assign({}, this.defaultForm);
      }
    },
  }
</script>
