<template>
  <v-container>
    <ValidationObserver ref="observer" v-slot="{ validate, reset }">
      <form v-on:submit.prevent="save">
        <v-card>
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{$t('company_topup')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <template>
                  <v-col cols="12" sm="6">
                    <ValidationProvider name="paytype" rules="required" v-slot="{ errors }">
                      <v-autocomplete
                        :error-messages="errors"
                        :label="$t('paytype')"
                        name="paytype"
                        :items="PayTypes"
                        :filter="customFilter"
                        item-text="name"
                        item-value="code"
                        v-model="editedItem.paytype"
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider name="amount" :rules="amountRules" v-slot="{ errors }">
                      <v-text-field
                        :error-messages="errors"
                        :label="$t('amount')"
                        name="amount"
                        type="number"
                        v-model="editedItem.amount"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider name="from_bankflag" rules="required" v-slot="{ errors }">
                      <v-combobox
                        :error-messages="errors"
                        :label="$t('from_bankflag')"
                        name="from_bankflag"
                        :items="Banks"
                        item-text="name"
                        item-value="name"
                        :return-object="false"
                        v-model="editedItem.bankflag"
                      >
                      </v-combobox>
<!--                      <v-combobox-->
<!--                        :error-messages="errors"-->
<!--                        :label="$t('from_bankflag')"-->
<!--                        name="bankflag"-->
<!--                        :items="bankList"-->
<!--                        :hint="$t('type if your bank does not appear in the list')"-->
<!--                        persistent-hint-->
<!--                        :filter="customFilter"-->
<!--                        item-text="name"-->
<!--                        item-value="code"-->
<!--                        v-model="editedItem.bankflag"-->
<!--                      >-->
<!--                        <template v-slot:no-data>-->
<!--                          <v-list-item>-->
<!--                            <v-list-item-content>-->
<!--                              <v-list-item-title>-->
<!--                                {{$t('type if your bank does not appear in the list')}}-->
<!--                              </v-list-item-title>-->
<!--                            </v-list-item-content>-->
<!--                          </v-list-item>-->
<!--                        </template>-->
<!--                      </v-combobox>-->
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider name="from_cardname" rules="required" v-slot="{ errors }">
                      <v-text-field
                        :error-messages="errors"
                        :label="$t('from_cardname')"
                        persistent-hint
                        name="cardname"
                        type="text"
                        v-model="editedItem.cardname"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider name="cardnumber" rules="required" v-slot="{ errors }">
                      <v-text-field
                        :error-messages="errors"
                        :label="$t('from_cardnumber')"
                        v-model="editedItem.cardnumber"
                        name="cardnumber"
                        type="number"
                        auto-grow
                        rows="3"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
<!--                  <v-col cols="12" sm="6">-->
<!--                    <ValidationProvider name="remark" rules="" v-slot="{ errors }">-->
<!--                      <v-textarea-->
<!--                        :error-messages="errors"-->
<!--                        :label="$t('remark')"-->
<!--                        v-model="editedItem.remark"-->
<!--                        name="remark"-->
<!--                        type="number"-->
<!--                        auto-grow-->
<!--                        rows="1"-->
<!--                      ></v-textarea>-->
<!--                    </ValidationProvider>-->
<!--                  </v-col>-->
                </template>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="busy" @click="save" color="blue darken-1" text>{{$t('save')}}</v-btn>
          </v-card-actions>

          <v-dialog v-model="dialog" persistent max-width="570">
            <v-card>
              <v-progress-linear color="primary lighten-2" :value="timerValue"></v-progress-linear>
              <v-card-title>{{$t('instruction')}}</v-card-title>
              <v-card-subtitle>{{$t('topup_desc')}}</v-card-subtitle>
              <v-card-text>
                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-title>{{$t('amount')}}</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ amount}}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>{{$t('to_bankflag')}}</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ card.bankflag }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>{{$t('to_cardnumber')}}</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ card.cardnumber}}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>{{$t('to_cardname')}}</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ card.cardname}}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item v-if="card.location">
                    <v-list-item-title>{{$t('to_location')}}</v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ card.location}}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item v-if="card.refnum">
                    <v-list-item-title>
                      <div>{{$t('ref_num')}}</div>
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right">
                      {{ card.refnum}}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                      <v-alert type="info" dense border="left">
                        {{$t('topup_desc_2')}}
                      </v-alert>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDialog">{{$t('continue')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </form>
    </ValidationObserver>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import * as ting from '../../../constants/ting'
  import y3Mixins from '../../../mixins/y3Mixins'
  import { required, min_value, max_value } from 'vee-validate/dist/rules';
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'

  extend('required', required)
  extend('min_value', min_value)
  extend('max_value', max_value)

  export default {
    name: 'CompanyTopup',
    mixins: [y3Mixins],
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
      amountRules () {
        switch (this.editedItem.paytype) {
          case ting.REMIT:
            return 'required|min_value:100|max_value:49999'
          case ting.QRCODE:
            return 'required|min_value:500|max_value:5000'
          case ting.ONLINE:
            return 'required|min_value:500|max_value:5000'
          case ting.QUICK:
            return 'required|min_value:500|max_value:5000'
          default:
            return 'required'
        }
      },
      timerValue () {
        return (this.maxMilli - this.curMilli) / this.timeLimitMilli * 100
      }
    },
    data: function() {
      return {
        bankList: [],
        dialog: false,
        busy: false,
        maxMilli: 0,
        curMilli: 0,
        timeLimitMilli: 10 * 60 * 1000,
        timerInterval: null,
        search: '',
        amount: 0,
        card: {
          bankflag: '',
          cardnumber: '',
          cardname: '',
          location: ''
        },
        editedItem: {
          amount: 0,
          bankflag: '',
          paytype: '',
          cardname: '',
          cardnumber: '',
          remark: '',     // Optional
        },
        defaultItem: {
          amount: 0,
          bankflag: '',
          paytype: '',
          cardname: '',
          cardnumber: '',
          remark: '',     // Optional
        },
      }
    },
    methods: {
      save() {
        this.addTransaction();
      },
      addTransaction() {
        const _this = this;

        this.$refs.observer.validate().then(result => {
          if (result) {
            _this.busy = true;

            axios.post(`/app/api/client/${_this.user.user_id}/topup`, _this.editedItem)
              .then(function (response) {
                _this.editedItem = Object.assign({}, _this.defaultItem);
                _this.$refs.observer.reset()
                // console.log(response.data) // eslint-disable-line no-console

                _this.amount = response.data.data.amount
                _this.card = response.data.data.card
                _this.dialog = true

                _this.startTimer()
              })
              .catch(function (error) {
                // Show error.
                // console.log(error) // eslint-disable-line no-console
                _this.$store.dispatch('snackbar/openSnackbar', {
                  message: error.response.data.message,
                  color: 'error',
                })
              })
              .finally(function () {
                _this.busy = false;
              })
          }
        })
      },
      startTimer () {
        this.maxMilli = Date.now() + this.timeLimitMilli
        this.curMilli = Date.now()

        this.timerInterval = setInterval(() => {
          this.curMilli = Date.now()
          if (this.curMilli >= this.maxMilli) {
            this.closeDialog()
          }
        }, 1000);
      },
      closeDialog () {
        this.dialog = false
        this.resetTimer()
      },
      resetTimer () {
        clearInterval(this.timerInterval)
        this.maxMilli = 0
        this.curMilli = 0
      }
    },
  }
</script>
