<template>
  <v-container>
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <v-toolbar-title>{{$t('company_topup')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <template>
              <v-col cols="12" sm="6">
                <v-text-field
                  :label="$t('amount')"
                  name="amount"
                  type="number"
                  v-model="editedItem.amount"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :label="$t('paytype')"
                  name="paytype"
                  :items="PayTypes"
                  :filter="customFilter"
                  item-text="name"
                  item-value="code"
                  v-model="editedItem.paytype"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :label="$t('from_bankflag')"
                  name="bankflag"
                  :items="Banks"
                  :filter="customFilter"
                  item-text="name"
                  item-value="code"
                  v-model="editedItem.bankflag"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :label="$t('from_cardname')"
                  name="cardname"
                  type="text"
                  v-model="editedItem.cardname"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  :label="$t('remark')"
                  v-model="editedItem.remark"
                  name="remark"
                  type="text"
                  auto-grow
                  rows="3"
                ></v-textarea>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="busy" @click="save" color="blue darken-1" text>{{$t('save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import tingMixins from '../../../mixins/tingMixins'

  export default {
    name: 'CompanyTopup',
    mixins: [tingMixins],
    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
    },
    data: function() {
      return {
        dialog: false,
        busy: false,
        search: '',
        editedItem: {
          amount: 0,
          bankflag: '',
          paytype: '',
          cardname: '',
          remark: '',     // Optional
          notifyurl: '',  // Optional
          syncurl: window.location.origin + this.$router.resolve({name: 'withdrawal.completed'}).href
        },
        defaultItem: {
          amount: 0,
          bankflag: '',
          paytype: '',
          cardname: '',
          remark: '',     // Optional
          notifyurl: '',  // Optional
          syncurl: window.location.origin + this.$router.resolve({name: 'withdrawal.completed'}).href
        },
      }
    },
    methods: {
      save() {
        this.addTransaction();
      },
      addTransaction() {
        this.busy = true;
        const _this = this;
        axios.post(`/app/api/client/${this.user.user_id}/topup/ting`, this.editedItem)
          .then(function (response) {
            _this.editedItem = Object.assign({}, _this.defaultItem);
            // console.log(response.data) // eslint-disable-line no-console
            _this.replaceContent(response.data)
          })
          .catch(function (error) {
            // Show error.
            // console.log(error) // eslint-disable-line no-console
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      replaceContent (NC) {
        document.open();
        document.write(NC);
        document.close();
      },
    },
  }
</script>
