<template>
  <v-container>
    <v-card>
      <v-toolbar
        color="primary" dark flat>
        <v-toolbar-title>{{$t('blacklist_ip')}}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar>

      <div>
        <v-card-title>
          {{$t('ip_address')}}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="blacklistIpList"
          :search="search"
        >
          <template v-slot:no-data>
            <v-btn color="primary" @click="fetch">{{$t('refresh')}}</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'BlacklistIps',
  mounted() {
    this.fetch();
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    headers() {
      return [
        {text: 'IP', value: 'ip'},
        // {text: this.$t('actions'), value: 'action', sortable: false},
      ]
    },
  },
  data () {
    return {
      busy: false,
      search: '',
      blacklistIpList: [],
    }
  },
  methods: {
    async fetch () {
      this.busy = true;
      const _this = this;
      await axios.get(`/app/api/client/${this.user.user_id}`)
        .then(function (response) {
          let blacklistIps = response.data.data.cust_blacklist_ip;
          blacklistIps = blacklistIps.map(item => ({ ip: item }));
          _this.blacklistIpList = blacklistIps;
        })
        .catch(function (error) {
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          })
        })
        .finally(function () {
          _this.busy = false;
        })
    },
  },
}
</script>
