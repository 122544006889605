import axios from 'axios'

let currencyMixins = {
  data: () => ({
    CurrencyList: [],
  }),
  methods: {
    fetchCurrency() {
      let _this = this;
      return axios
        .get('/app/api/currency')
        .then(function (response) {
          _this.CurrencyList = response.data.data;
        })
        .catch(function (error) {
          _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
          })
        })
    }
  },
}

export default currencyMixins;
