<template>
  <div>
    <v-card-title>
      {{$t('all')}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="userList"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{$t('staff')}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">{{$t('new_staff')}}</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :label="$t('username')"
                        v-model="editedItem.username"
                        name="username"
                        type="text"
                        :disabled="editedIndex > -1"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        id="password"
                        v-model="editedItem.password"
                        :label="$t('password')"
                        name="password"
                        type="password"
                        :disabled="editedIndex > -1"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :label="$t('email')"
                        v-model="editedItem.email"
                        name="email"
                        type="text"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :label="$t('phone')"
                        :prefix="`+${editedItem.phone.code}`"
                        v-model="editedItem.phone.number"
                        name="phone"
                        prepend-icon="mdi-phone"
                        type="text"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :label="$t('display_name')"
                        v-model="editedItem.display_name"
                        name="display_name"
                        type="text"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :label="$t('address')"
                        v-model="editedItem.address"
                        name="address"
                        type="text"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-radio-group v-model="editedItem.user_type" row>
                        <v-radio
                          :label="$t('manager')"
                          :value="editedIndex > -1 ? UserType.MANAGER : 'manager'"
                          :disabled="editedIndex > -1"
                        ></v-radio>
                        <v-radio
                          :label="$t('cs')"
                          :value="editedIndex > -1 ? UserType.CS_AGENT : 'cs agent'"
                          :disabled="editedIndex > -1"
                        ></v-radio>
                        <v-radio
                          :label="$t('reporter')"
                          :value="editedIndex > -1 ? UserType.REPORTER : 'reporter'"
                          :disabled="editedIndex > -1"
                        ></v-radio>
                        <v-radio
                          v-if="isRoot"
                          :label="$t('cs_bot')"
                          :value="editedIndex > -1 ? UserType.CS_AGENT_BOT : 'cs agent b'"
                          :disabled="editedIndex > -1"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <template v-if="
                      editedItem.user_type === UserType.CS_AGENT || 
                      editedItem.user_type === UserType.CS_AGENT_BOT ||
                      editedItem.user_type === 'cs agent' ||
                      editedItem.user_type === 'cs agent b'
                    ">
                      <v-col cols="12" sm="6">
                        <v-text-field
                          :label="$t('max_tx_amt')"
                          v-model.number="editedItem.max_tx_limit"
                          name="max_tx_limit"
                          type="number"
                        />
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          :label="$t('max_assign_limit')"
                          v-model.number="editedItem.max_assign_limit"
                          name="max_assign_limit"
                          type="number"
                        />
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-combobox
                          :label="$t('currency')"
                          name="currency_code"
                          :items="currencyList"
                          item-text="currency_code"
                          item-value="currency_code"
                          :return-object="false"
                          v-model="editedItem.currency_code"
                        >
                        </v-combobox>
                      </v-col>
                    </template>

                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">{{$t('cancel')}}</v-btn>
                <v-btn color="blue darken-1" text @click="save" :loading="busy">{{$t('save')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.user_type="{ item }">
        {{ getKeyByValue(UserType, item.user_type) }}
      </template>
      <template v-slot:item.user_status="{ item }">
        {{ getKeyByValue(UserStatus, item.user_status) }}
      </template>
      <template v-slot:item.max_tx_limit="{ item }">
        {{ item.max_tx_limit ? item.max_tx_limit.toLocaleString(undefined, {minimumFractionDigits: 2}) : ''}}
      </template>
      <template v-slot:item.max_assign_limit="{ item }">
        {{ item.max_assign_limit ? item.max_assign_limit.toLocaleString() : '' }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{$t('edit')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="deleteItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{$t('delete')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="resetPassword(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-lock-reset
            </v-icon>
          </template>
          <span>{{$t('Reset Password')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              @click="resetWithdrawalKey(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-key-change
            </v-icon>
          </template>
          <span>{{$t('Reset Withdraw Key')}}</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="fetch">{{$t('refresh')}}</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import axios from 'axios'
  import contactMixins from '../../mixins/contactMixins'
  import {mapGetters} from "vuex";

  export default {
    name: 'StaffContact',
    mixins: [contactMixins],
    props: {
      query: {
        type: String,
        default: ''
      },
    },
    mounted() {
      this.fetch();
      this.fetchCurrency();
    },
    computed: {
      ...mapGetters({
        isRoot: 'auth/isRoot',
      }),
      headers () {
        return [
          {
            text: this.$t('user_id'),
            align: 'left',
            sortable: true,
            value: 'user_id',
          },
          { text: this.$t('display_name'), value: 'display_name' },
          { text: this.$t('username'), value: 'username' },
          { text: this.$t('user_type'), value: 'user_type' },
          { text: this.$t('max_tx_amt'), value: 'max_tx_limit' },
          { text: this.$t('max_assign_limit'), value: 'max_assign_limit' },
          { text: this.$t('user_status'), value: 'user_status' },
          { text: this.$t('actions'), value: 'action', sortable: false },
        ]
      },
      formTitle () {
        return this.editedIndex === -1 ? this.$t('new_staff') : this.$t('edit_staff')
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      search(newVal) {
        this.$router.push({ query: { ...this.$route.query, q: newVal } });
      },
      '$route.query.q': function(val) {
        this.search = val;
      }
    },
    data () {
      return {
        dialog: false,
        busy: false,
        search: this.query,
        userList: [],
        currencyList: [],
        editedIndex: -1,
        editedItem: {
          user_id: null,
          username: '',
          password: '',
          display_name: '',
          // status: true,
          phone: {
            code: '60',
            number: '',
          },
          client_name: '',
          address: '',
          email: '',
          user_type: '',
          max_tx_limit: 0,
          max_assign_limit: 0,
          currency_code: null,
        },
        defaultItem: {
          user_id: null,
          username: '',
          password: '',
          display_name: '',
          // status: true,
          phone: {
            code: '60',
            number: '',
          },
          client_name: '',
          address: '',
          email: '',
          user_type: '',
          max_tx_limit: 0,
          max_assign_limit: 0,
          currency_code: null,
        },
      }
    },
    methods: {
      async fetch () {
        this.busy = true;
        const _this = this;
        await axios.get('/app/api/user')
          .then(function (response) {
            _this.userList = response.data.data;
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      fetchCurrency () {
        this.busy = true;
        let _this = this;
        axios.get('/app/api/currency')
          .then(function (response) {
            _this.currencyList = response.data.data;
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      editItem (item) {
        this.editedIndex = this.userList.indexOf(item)
        this.editedItem = Object.assign({}, item)

        if (!this.editedItem.phone) {
          this.editedItem.phone = {
            code: '60',
            number: '',
          }
        }
        this.dialog = true
      },
      deleteItem (item) {
        if (confirm(this.$t('delete_confirmation'))) {
          this.busy = true;
          const _this = this;
          axios.delete(`/app/api/user/${item.user_id}`)
            .then(function (response) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: response.data.message,
                color: 'success',
              })
              // _this.userList.splice(index, 1)
              _this.fetch();
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.busy = false;
            })
        }
      },
      resetPassword (item) {
        if (confirm(this.$t('reset_password_confirmation'))) {
          this.busy = true;
          const _this = this;
          axios.post(`/app/api/user/${item.user_id}/resetpassword`)
            .then(function (response) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: response.data.message,
                color: 'success',
              });
              alert(_this.$t('new_password') + ': ' + response.data.data.password);
              _this.fetch();
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.busy = false;
            })
        }
      },
      resetWithdrawalKey (item) {
        if (confirm(this.$t('reset_withdraw_key_confirmation'))) {
          this.busy = true;
          const _this = this;
          axios.post(`/app/api/user/${item.user_id}/resetwithdrawkey`)
            .then(function (response) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: response.data.message,
                color: 'success',
              })
              alert(_this.$t('new_withdraw_key') + ': ' + response.data.data.withdraw_key);
              _this.fetch();
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.busy = false;
            })
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.phone = Object.assign({}, this.defaultItem.phone);
          this.editedIndex = -1;
        }, 300)
      },
      save () {
        if (this.editedIndex > -1) {
          this.updateUser();
        } else {
          this.addUser();
        }
      },
      updateUser () {
        this.busy = true;
        const _this = this;
        axios.put(`/app/api/user/${this.editedItem.user_id}`, {
          user: {
            // username: this.editedItem.username,
            // password: this.editedItem.password,
            email: this.editedItem.email,
            phone: {
              code: this.editedItem.phone.code,
              number: this.editedItem.phone.number,
            },
            display_name: this.editedItem.display_name,
            // status: this.editedItem.status,
            address: this.editedItem.address,
            // user_type: this.editedItem.user_type,
            max_transaction_limit: this.editedItem.max_tx_limit,
            max_assign_limit: this.editedItem.max_assign_limit,
            currency_code: this.editedItem.currency_code,
          }
        })
          .then(function () {
            _this.fetch();
            _this.close()
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      addUser () {
        this.busy = true;
        const _this = this;

        axios.post('/app/api/user', {
          user: {
            username: this.editedItem.username,
            password: this.editedItem.password,
            email: this.editedItem.email,
            phone: {
              code: this.editedItem.phone.code,
              number: this.editedItem.phone.number,
            },
            display_name: this.editedItem.display_name,
            status: this.editedItem.status,
            address: this.editedItem.address,
            user_type: this.editedItem.user_type,
            ...((this.editedItem.user_type === 'cs agent' || this.editedItem.user_type === 'cs agent b') && {
              max_transaction_limit: Number(this.editedItem.max_tx_limit),
              max_assign_limit: Number(this.editedItem.max_assign_limit),
              currency_code: this.editedItem.currency_code,
            }),
          }
        })
          .then(function () {
            _this.fetch();
            _this.close()
          })
          .catch(function (error) {
            // Show error.
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
    },
  }
</script>
