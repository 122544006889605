export const ApiVersion = {
    API_V1: 1,
    API_V2: 2,
    API_V3: 3,
};

export const API_VERSION = Object.values(ApiVersion);
export const API_VERSION_LATEST = ApiVersion.API_V3;

export const ApiVersionDesc = {
    [ApiVersion.API_V1]: 'api_version_desc_v1',
    [ApiVersion.API_V2]: 'api_version_desc_v2',
    [ApiVersion.API_V3]: 'api_version_desc_v3',
};

export const CvrMode = {
    NONE: 0,
    DECIMAL_DOWN: 1,
    DECIMAL_UP: 2,
    RUNNING_DOWN: 3,
};

export const CVR_MODE = Object.values(CvrMode);

export default {
    ApiVersion,
    API_VERSION,
    API_VERSION_LATEST,
    ApiVersionDesc,
    CvrMode,
    CVR_MODE,
}
