<template>
  <v-container>
    <form>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('playground_signature') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-text-field label="API Key" v-model="editedItem.api_key" />
              </v-col>
              <v-col cols="12">
                <v-text-field 
                  type="password"
                  label="API Secret" 
                  v-model="editedItem.api_secret" 
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  :label="$t('HTTP Method')"
                  :items="supportedHttpMethod"
                  v-model="editedItem.http_method"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-combobox
                  :label="$t('API Path')"
                  :items="supportedApiPath"
                  v-model="editedItem.api_path"
                  :return-object=false
                >
                </v-combobox>
              </v-col>
              <v-col cols="12">
                <v-text-field 
                  label="Request Time (Epoch in milliseconds)" 
                  v-model="editedItem.req_time" 
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Request Data (in JSON string)" 
                  v-model="editedItem.req_json_str" 
                  outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="blue darken-1" 
            text 
            @click="constructHmacSignature"
          >
            {{$t('submit')}}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="editedItem.result" class="mt-4">
        <v-card-title>
          <span class="headline">Result</span>
          <v-spacer></v-spacer>
          <v-icon @click="copyResult()">mdi-content-copy</v-icon>
        </v-card-title>
        <v-card-text>
          Step 1: Sort by object key and join by & (exclude empty/null field):
          <br />
          {{editedItem.result.s1}}
        </v-card-text>
        <v-card-text>
          Step 2: Convert to base 64:
          <br />
          {{editedItem.result.s2}}
        </v-card-text>
        <v-card-text>
          Step 3: HMAC plain text before sign:
          <br />
          {{editedItem.result.s3}}
        </v-card-text>
        <v-card-text>
          Step 4: Sign HMAC plain text using API secret and convert to HEX:
          <br />
          {{editedItem.result.s4}}
        </v-card-text>
        <v-card-text>
          Step 5: Finally get the Authorization Header: 
          <br />
          {{editedItem.result.s5}}
        </v-card-text>
      </v-card>
    </form>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'PlaygroundSignature',
    computed: {
      ...mapGetters({
        isRoot: 'auth/isRoot',
        isManager: 'auth/isManager',
        isCsAgent: 'auth/isCsAgent',
        isClient: 'auth/isClient',
        isClientCsa: 'auth/isClientCsa',
      }),
      supportedHttpMethod() {
        return [
          {
            value: 'GET',
            text: 'GET Method (For Balance Enquiry API only)',
          },
          {
            value: 'POST',
            text: 'POST Method (For all other API and client callback)',
          },
        ];
      },
      supportedApiPath() {
        return [
          {
            value: '/be/client/api/v1/collect',
            text: 'Collect API (/be/client/api/v1/collect)',
          },
          {
            value: '/be/client/api/v1/collectui',
            text: 'Collect UI API (/be/client/api/v1/collectui)',
          },
          {
            value: '/be/client/api/v1/pay',
            text: 'Pay API (/be/client/api/v1/pay)',
          },
          {
            value: '/be/client/api/v1/enqorder',
            text: 'Order Enquiry API (/be/client/api/v1/enqorder)',
          },
          {
            value: '/be/client/api/v1/balance',
            text: 'Balance Enquiry API (/be/client/api/v1/balance)',
          },
        ];
      }
    },
    data: function() {
      return {
        editedItem: {
          api_key: null,
          api_secret: null,
          http_method: null,
          api_path: null,
          req_time: Date.now(),
          req_json_str: null,
          result: null,
        },
      };
    },
    methods: {
      async constructHmacSignature() {

        // TODO: More testing before update to live-vn and bo-live

        const editedItem = this.editedItem;

        if (
          !editedItem.api_key ||
          !editedItem.api_secret ||
          !editedItem.http_method ||
          !editedItem.api_path ||
          !editedItem.req_time ||
          !editedItem.req_json_str
        ) {
          this.$store.dispatch('snackbar/openSnackbar', {
            message: 'Please fill in all field',
            color: 'error',
          });
          return;
        }

        let jsonData;
        
        try {
          jsonData = JSON.parse(editedItem.req_json_str);
        }
        catch (err) {
          this.$store.dispatch('snackbar/openSnackbar', {
            message: 'Invalid JSON string',
            color: 'error',
          });
          return;
        }

        // Step 1: Sort by object key and join by & (exclude empty/null field)

        const result = {};

        let bodyBase64Str = Object
          .entries(jsonData)
          .sort((x, y) => x[0].localeCompare(y[0]))
          .reduce((agg, x, idx) => agg + `${idx ? '&' : ''}${x[0]}=${x[1]}`, '');

        result.s1 = bodyBase64Str;

        // Step 2: Convert to base 64

        bodyBase64Str = Buffer.from(bodyBase64Str).toString('base64');
        result.s2 = bodyBase64Str;

        // Step 3: HMAC plain text before sign

        const hmacPlainTxt = [
          editedItem.http_method, 
          editedItem.api_path, 
          editedItem.req_time, 
          bodyBase64Str
        ].join(':');
        
        result.s3 = hmacPlainTxt;

        // Step 4: Sign HMAC plain text using API secret and convert to HEX
        
        const hmacSignTxt 
          = await this.hmacSign(editedItem.api_secret, hmacPlainTxt);

        result.s4 = hmacSignTxt;

        // Step 5: Finally get the Authorization Header

        const api_key = editedItem.api_key;
        const req_time = editedItem.req_time;

        result.s5 = 'Authorization: ';
        result.s5 += `Y3-HMAC-SHA256 ${api_key}:${req_time}:${hmacSignTxt}`;

        // Update result in UI

        this.editedItem.result = result;
      },
      async hmacSign(api_secret, hmacPlainTxt) {
        let textEncoder = new TextEncoder("utf-8");
        let encAlg = { name: "HMAC", hash: "SHA-256" };

        let cryptoKey = await crypto.subtle.importKey(
          "raw", textEncoder.encode(api_secret), encAlg, false, 
          ["sign", "verify"]
        );

        const hmacSignByte = await crypto.subtle.sign(
          encAlg.name, cryptoKey, textEncoder.encode(hmacPlainTxt)
        );
        const hmacSignTxt = this.buf2hex(hmacSignByte);

        return hmacSignTxt;
      },
      buf2hex(buffer) {
        return [...new Uint8Array(buffer)]
          .map(x => x.toString(16).padStart(2, '0'))
          .join('');
      },
      copyResult() {
        let s = '';

        s += 'Step 1: Sort by object key and join by & (exclude empty/null field):\n';
        s += (this.editedItem.result.s1 || '') + '\n\n';
        s += 'Step 2: Convert to base 64:\n';
        s += (this.editedItem.result.s2 || '') + '\n\n';
        s += 'Step 3: HMAC plain text before sign:\n';
        s += (this.editedItem.result.s3 || '') + '\n\n';
        s += 'Step 4: Sign HMAC plain text using API secret and convert to HEX:\n';
        s += (this.editedItem.result.s4 || '') + '\n\n';
        s += 'Step 5: Finally get the Authorization Header:\n';
        s += (this.editedItem.result.s5 || '') + '\n';

        navigator.clipboard
          .writeText(s)
          .then(() => {
            this.$store.dispatch('snackbar/openSnackbar', {
              message: this.$t('copy_success', ['Result']),
              color: 'success',
            })
          });
      }
    },
  }
</script>
