import * as y3 from '../constants/y3'

let y3Mixins = {
  async mounted() {
    switch (process.env.VUE_APP_COUNTRY) {
      case 'CN':
        this.Banks = (await import('../constants/CN')).BANKLIST;
        break;
      case 'SG':
        this.Banks = (await import('../constants/SG')).BANKLIST;
        break;
      case 'PH':
        this.Banks = (await import('../constants/PH')).BANKLIST;
        break;
      default:
        break;
    }
  },
  data: () => ({
    PayTypes: y3.PayType,
    PayTypeEnum: y3.PayTypeEnum,
    Banks: [],
  }),
  methods: {
    customFilter(item, queryText, itemText) { // eslint-disable-line no-unused-vars
      const textOne = item.code.toLowerCase()
      const textTwo = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },
    findNameByCode(code) {
      try {
        let name = this.Banks.find(x => x.code === code).name
        return name ? name : code
      } catch {
        return code
      }
    }
  },
}

export default y3Mixins;
